// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_3csaFHbJr05baGI4he", // sketch2r
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/aEUfZR7Vs3xcebe5li", // sketch2r

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Sketch to Realistic AI 🎨",
    HOME_PAGE_TITLE: "Home - Sketch to Realistic AI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Sketch to Realistic AI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Sketch to Realistic AI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to generate realistic images from sketch images, choose an image and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "sketch_to_realistic_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/sketch-to-realistic-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;